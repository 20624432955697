/* You can add global styles to this file, and also import other style files */

* {
  touch-action: manipulation;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', 'Arial',sans-serif;
}

.main-router-outlet + * {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.expand-router + * {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}
